
.footer-columns {
    background: $primary;

    > .container {
        border-bottom: 1px solid rgba($white, 0.15);
        padding-top: 60px;
        padding-bottom: 60px;
    }

    aside {
        &:nth-of-type(1) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            svg {
                width: 150px;
                height: 70px;
                color: $white;
                margin-bottom: 15px;
            }

            p {
                color: $white-lilac;
                margin-bottom: 0;

                @include font-size($font-small);
            }
        }

        &:nth-of-type(2),
        &:nth-of-type(3) {
            @include media-breakpoint-down(lg) {
                margin-top: 25px;
            }

            h5 {
                color: $white-lilac;
                margin-bottom: 20px;

                @include font-size($font-regular);

                font-weight: $font-weight--medium;

                @include media-breakpoint-down(lg) {
                    text-decoration: underline;
                }
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    margin-bottom: 10px;

                    a {
                        color: $white-lilac;

                        @include font-size($font-regular);

                        font-weight: $font-weight--light;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        &:nth-of-type(4) {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;

            @include media-breakpoint-down(lg) {
                align-items: flex-start;
                margin-top: 25px;
            }

            h5 {
                color: $white-lilac;
                margin-bottom: 10px;

                @include font-size($font-regular);

                font-weight: $font-weight--medium;

                @include media-breakpoint-down(lg) {
                    text-decoration: underline;
                }
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                li {
                    margin-left: 5px;

                    @include media-breakpoint-down(lg) {
                        margin-left: 0;
                        margin-right: 5px;
                    }

                    a {
                        width: 40px;
                        height: 40px;
                        border-radius: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @include media-breakpoint-down(lg) {
                            border: 1px solid rgba($white, 0.1);
                        }

                        &:hover {
                            background: rgba($white, 0.1);
                        }

                        svg {
                            width: 15px;
                            height: 15px;
                            color: $white;

                            &.whatsapp-icon {
                                width: 18px;
                                height: 18px;
                            }

                            &.youtube-icon {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.footer-copyright {
    background: $primary;

    &__login {
        padding: 15px;

        p {
            color: rgba($white-lilac, 0.5);
            margin-bottom: 0;

            @include font-size($font-small);

            a {
                svg {
                    width: 20px;
                    height: 20px;
                    color: $white-lilac;
                }
            }
        }
    }

    &__content {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include media-breakpoint-down(lg) {
            justify-content: center;
        }

        p {
            color: rgba($white-lilac, 0.5);
            margin-bottom: 0;

            @include font-size($font-small);

            a {
                color: rgba($white-lilac, 0.5);

                &:hover {
                    color: $white-lilac;
                }
            }
        }
    }
}
